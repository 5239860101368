<template>
  <div>
    <img alt=":D" src="../assets/logo.png">
    <h1>Site en construction !</h1>
  </div>
</template>

<script>
export default {
  name: 'AppConstruction'
}
</script>