<template>
  <AppConstruction />
</template>

<script>
// @ is an alias to /src
import AppConstruction from '@/components/AppConstruction.vue'

export default {
  name: 'HomeView',
  components: {
    AppConstruction
  }
}
</script>
